import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";

i18n
.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// .use(LanguageDetector)
.init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "Hey! Are":"Hey! Are",
        "YOU":"YOU",
        "ready to learn a new language": "ready to learn a new language",
      }
    },
    es: {
      translations: {
        "Hey! Are":"¡Oye! ¿Está listo",
        "YOU":"Sumercé",
        "ready to learn a new language?": "para aprender un nuevo idioma?",

        "Home":"Inicio",
        'Our Community':'Nuestra Comunidad',
        "Get in Touch":"Contáctanos",

        " offers private lessons in Spanish and English. We welcome you to become part of our community. Instead of just studying vocabulary and grammar, learn to speak through a human connection. With us, you will learn at your own pace, from any location, but with enough structure to keep you accountable to your goal. Don't let another year pass by without learning your target language!":
        " ofrece lecciones privadas en Inglés y Español. Te invitamos a ser parte de nuestra comunidad. No sólo estudiarás gramática y vocabulario, sino aprenderás a soltar la lengua para sostener una conversación con confianza. Con nosotros, aprenderás a tu propio ritmo, desde cualquier lugar, pero con la estructura suficiente para que alcances tu objetivo personal. No dejes pasar otro año sin aprender ese idioma soñado.",

        "And you are?":"¿Y quién eres tú?",

        "World Traveler":'Viajero del Mundo',
        "Looking to build the confidence to express yourself in a new country?":
        '¿Buscando construir la confianza de expresarte en un nuevo país?',

        'Professional':'Profesional',
        'Want to prepare yourself for the bilingual work force?':'¿Quieres preparte para trabajar en una compañía bilingüe o una multinacional?',

        'Perfectionist':'Perfeccionista',
        'Ready to level up your grammar and pronunciation with native Spanish speakers and find your fluency?':'¿Estás listo para llevar tu gramática y pronunciación a otro nivel con nativos y encontrar así la fluídez en el idioma?',

        'Proud Parent':'Padre Orgulloso',
        'Would you like to give your children the gift of a new language and a bright future?':'¿Te gustaría brindarle a tus hijos el regalo de un nuevo idioma y un mejor futuro?',

        "Wanna learn more?":'¿Quieres aprender más?',
        "15% off Spanish courses!":"¿Quieres aprender más?",
        "Let's get in touch!":"¡¡Contáctanos!!",

        "<Read more...>":"<Lee más...>",
      }
    },

  },
  // lng:'es',
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
