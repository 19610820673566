import React, { Fragment, Suspense, lazy } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";


import { I18nextProvider } from "react-i18next";

// import { TribeClient } from "@tribeplatform/gql-client"

// const client = new TribeClient({
//   graphqlUrl: "https://app.tribe.so/graphql",
// })

// const getAccessToken = async (usernameOrEmail, password) => {
//   const guestTokens = await client.getTokens(
//     { networkDomain: "community.chatwithastrid.com" },
//     "basic"
//   )
//   client.setToken(guestTokens.accessToken)

//   const { accessToken, refreshToken } = await client.auth.login(
//     {
//       input: { usernameOrEmail, password },
//     },
//     "basic"
//   )

//   console.log({ accessToken, refreshToken })
// }

// getAccessToken("chatwithastrid@gmail.com", "Sunflower22!")
// import { TribeClient } from "@tribeplatform/gql-client"

// const client = new TribeClient({
//     clientId: "20dd2f81-9c7289f17028",
//     clientSecret: "c4735d1fa4bd44e8a4a4dd783ad093ac",
//     graphqlUrl: "https://app.tribe.so/graphql",
// })

// client.generateToken({
//     networkId: "16rK9MJvf3",
//     memberId: "Astrid",
// }).then(async accessToken => {
//     console.log(accessToken)
// })

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));



function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            {/* <I18nextProvider i18n={i18n}> */}
              <Switch>
                <Route path="/c">
                  <LoggedInComponent />
                </Route>
                <Route>
                  <LoggedOutComponent />
                </Route>
              </Switch>
            {/* </I18nextProvider> */}
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
